import FAQSection from 'components/pages/pricing/FAQSection';
import PricingSection from 'components/pages/pricing/PricingSection';
import PackagesList from 'components/shared/PackagesSection/PackagesList';
import PageHeader from 'components/shared/PageHeader';
import Container from 'components/shared/Container';

import SEO from 'components/shared/SEO';
import routes from 'data/globals/routes';
import React from 'react';
import scrollTo from 'utilities/scrollTo';

import styled from 'styled-components';
import { breakpoints } from 'styles/variables';

import poirlogo from 'assets/images/poir.png';


const Pricing = ({ location }) => {
   const headerText = ['Inteligentny rozwój'];

   const packagesLinks = {
      link: {
         text: 'Poznaj pełne porównanie',
         to: 'pricing-section',
      },
      buttons: [
         {
            text: 'Wybierz Standard',
            to: routes.contact,
            state: { package: 'Standard' },
         },
         {
            text: 'Wybierz Standard Plus',
            to: routes.contact,
            state: { package: 'Standard Plus' },
         },
      ],
   };

   const ContainerBlock = styled.div`
     width: 100%;
     max-width: ${({ fullwidth }) => (fullwidth ? '100%' : '1100px')};
     padding: ${({ fullwidth }) => (fullwidth ? '0' : '0 15px')};

     margin: 0 auto;
     justify-content: center;
     ${breakpoints.xl`
        padding: 0;
     `}
  `;

   return (
      <>
         <SEO title="Inteligentny rozwój" />
         <PageHeader headerText={headerText} />
         <ContainerBlock>
            <img alt="poirlogo" src={poirlogo} className="poirLogo" style={{width: "100%"}} width="100%" />
         </ContainerBlock>
         <ContainerBlock>
           <p>
            Firma Proces Control Spółka z ograniczoną odpowiedzialnością realizuje Projekt dofinansowany z Funduszy Europejskich pt. „Opracowanie systemu informatycznego wymiany informacji medycznych z elementami predykcji”.
           </p>
         </ContainerBlock>
         <ContainerBlock>
           <p>
            <b>Cel projektu</b><br></br>
            Celem Projektu jest opracowanie systemu informatycznego (funkcjonującego pod nazwą Proces Control), działającego w formie aplikacji internetowej otwieranej przy użyciu aktualnej przeglądarki internetowej (wspierającej aktualne standardy wytwarzania oprogramowania internetowego), ułatwiającego sprawną wymianę informacji pomiędzy laboratorium diagnostycznym a osobami analizującymi i interpretującymi wyniki konkretnego badania.
           </p>
         </ContainerBlock>
         <ContainerBlock>
           <p>
             <b>Planowane efekty:</b><br></br>
             Powstałe w ramach Projektu rozwiązanie będzie wykorzystywane we współpracy medycznych laboratoriów diagnostycznych, w których uzyskiwane i wprowadzane do systemu są wyniki badań konkretnych prób i pacjentów oraz operacyjnej pracy lekarzy, którzy będą posiadali dostęp do tzw. „panelu lekarza”, w którym widoczne będą wyniki danego pacjenta, jego historia oraz sugestie systemu dotyczące dalszych postępowań w konkretnym przypadku na podstawie danych historycznych wybranego pacjenta oraz analizy porównawczej opartej o algorytm predykcyjny wspierający decyzje oraz przekazujący sugestie dotyczące dalszego postępowania z pacjentem. Zadaniem systemu Proces Control będzie usprawnienie oraz digitalizacja procesu wprowadzania danych przez pracowników laboratoriów diagnostycznych, jak również ustandaryzowanie formę wprowadzanych danych
           </p>
         </ContainerBlock>
         <ContainerBlock>
           <p>
            <b>Wartość projektu:</b> 1 000 000 zł<br></br>
            <b>Wkład funduszy europejskich:</b> 800 000 zł<br></br>
           </p>
         </ContainerBlock>
      </>
   );
};

export default Pricing;
